import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Partner from "../components/Common/Partner";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import PricingStyleOne from "../components/PricingPlans/PricingStyleOne";
import AboutOne from "../assets/images/about-one.png";

const CalismaSekilleri = () => (
    <Layout>
        <SEO title="Hakkımızda & Misyon & Vizyon " /> 

        <Navbar />
        <PageBanner pageTitle="Pixel Yazılım" />

        <PricingStyleOne />
   
        
        <Footer />
    </Layout>
)

export default CalismaSekilleri;
